/* .team-carousel .swiper {
  max-width: 410px;
  height: fit-content;
} */

.team-carousel img {
  border-radius: 16px;
  object-fit: cover;
  height: 537px;
}

.team-carousel .swiper-button-next,
.team-carousel .swiper-button-prev {
  color: #fff; /* Customize button color */
  z-index: 15; /* Bring buttons above swiper */
  width: 30px;
  height: 30px;
  background-color: #0361cc;
  border-radius: 50%;
  top: unset;
  margin-top: 24px;
}

.team-carousel .swiper-button-prev {
  left: 0;
}

.team-carousel .swiper-button-next {
  right: unset;
  left: 40px;
}

.team-carousel .swiper-button-next::after,
.team-carousel .swiper-button-prev::after {
  font-size: 16px;
}

.team-carousel .swiper-pagination {
  text-align: right;
}

.team-carousel .swiper-pagination-bullet {
  width: 32px;
  height: 4px;
  border-radius: 2px;
}

.team-carousel .swiper-horizontal > .swiper-pagination-bullets,
.team-carousel .swiper-pagination-bullets.swiper-pagination-horizontal {
  position: relative;
  margin-top: 30px;
}


@media screen and (min-width: 1024px) {
  .team-carousel .swiper {
    max-width: 410px;
    height: fit-content;
  }
  
}