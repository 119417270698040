.carousel-container {
  position: relative;
}

.carousel-container .swiper-slide {
  height: 100%;
}
.carousel-container .swiper-button-next,
.carousel-container .swiper-button-prev {
  color: #fff; /* Customize button color */
  z-index: 10; /* Bring buttons above swiper */
  width: 30px;
  height: 30px;
  background-color: #0361cc;
  border-radius: 50%;
}

.carousel-container > .swiper-button-prev {
  left: -48px;
}

.carousel-container .swiper-button-next {
  right: 28px;
}

.carousel-container .swiper-button-next::after,
.carousel-container .swiper-button-prev::after {
  font-size: 16px;
}

.carousel-container .swiper-pagination-bullets,
.carousel-container .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -16px !important;
}

.swiper-horizontal > .swiper-scrollbar,
.carousel-container .swiper-scrollbar.swiper-scrollbar-horizontal {
  display: none;
}
