body {
  font-family: "DM Sans", sans-serif;
}

.v-ap .swiper-horizontal{
  height: 180px !important;
}

/* .swiper-button-prev:after,.swiper-button-next:after{
  content: '' !important;
} */

.gr-2{
  grid-template-columns: 55% 45%;
}
.gr-3{
  grid-template-columns: 25% 25%;
}
@media (max-width: 991px) {
  .gr-2{
    grid-template-columns: 1fr;
  }
}
@media (max-width: 700px) {
  .gr-3{
    grid-template-columns: 35% 35%;
  }
}
@media (max-width: 450px) {
  .gr-3{
    grid-template-columns: 1fr;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 24px;
  --b: 8px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%,#0361CC) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation:l4 1s infinite steps(10);
}
@keyframes l4 {to{transform: rotate(1turn)}}

.slide-in-right {
	-webkit-animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-11-28 3:55:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(430px);
            transform: translateX(430px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(430px);
            transform: translateX(430px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
